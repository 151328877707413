import App from 'next/app'
import '../styles/globals.css'

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props
    const Layout = Component.Layout || 'div'

    return (
        <Layout>
          <Component {...pageProps} />
        </Layout>
    )
  }
}

export default MyApp
